import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/styles';
import Loader from 'react-loader-spinner';

import TopPanel from './TopPanel';
import MainNews from './MainNews';
import useGlobalStyles from '../../../theme/useGlobalStyles';
import { useBoard, useBoardsList } from '../../../api/boards';
import { ITheme } from '../../../theme/type';
import axiosRetry from 'axios-retry';
import axios from 'axios';
// @ts-ignore
import { ConcurrencyManager } from 'axios-concurrency';

export default function Main() {
  const globalClasses = useGlobalStyles({});
  const { boardId } = useParams();
  const [{ data: layout }, loading, load] = useBoard();
  const [{ data: boards }, loadingBoards, loadList] = useBoardsList();
  const [uuid, setUuid] = useState('');
  const theme: ITheme = useTheme();

  useEffect(() => {
    ConcurrencyManager(axios, 1);
    axiosRetry(axios, { retries: 1000, shouldResetTimeout: true, retryCondition: (error) => !!error });
    console.info("index load layout =", boardId, "uuid =", uuid)
    load({ id: parseInt(boardId), uuid });
    console.info("index load after uuid =", uuid)
    console.debug("index layout =", JSON.stringify(layout))
    loadList();
    console.debug("index boards =", JSON.stringify(boards))
  }, [])

  useEffect(() => {
    console.info("index effect uuid =", uuid, layout.uuid)
    setUuid(layout.uuid);
    console.info("index effect uuid set")
  }, [layout.uuid])

  const board = boards.find((b: any) => b.id === parseInt(boardId));

  return (
    <div className={globalClasses.root}>
      {(loading && !layout.layout) || (loadingBoards && !boards.length)
        ? (
          <div className={globalClasses.loader}>
            <Loader
              type="Grid"
              color={theme.palette.text.default}
              height={200}
              width={200}
            />
          </div>
        ) : (
              <>
                <TopPanel title={board?.title || board?.name} board={board} boardId={parseInt(boardId)}/>
                <MainNews board={board} getLayout={
                  () => {
                    console.info("index get layout board =", boardId, "uuid =", uuid)
                    load({id: parseInt(boardId), uuid})
                    console.debug("index get layout layout =", JSON.stringify(layout))
                    console.debug("index get layout uuid =", uuid)
                  }
                } layout={layout.layout} uuid={uuid}/>
              </>
          )
      }
    </div>
  )
}
